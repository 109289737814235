//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dialog } from "vant";
import { LocalStorage } from 'quasar'
import common from './common/common'
import api from './networking/api'
export default {
  data() {
    return {
      recordList:[],
      actionInfo:{},
      loading:false,
      isEdit:false,
      dialogFormVisible: true,
      storeListLoading: false,
      storesOptions: [],
      stores:[],
      curStore: '',
      storeName:'',
      storeId:''
    };
  },
  created() {
    let that = this;
            // var script = document.createElement('script');
            // script.src = 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js'
            // document.getElementsByTagName('body')[0].appendChild(script)
            // console.log(333);
            // script.onload = ()=>{
            //         var vConsole = new VConsole();
                    this.getStore();
                    this.requestJsSdkConfig()
            // }
  },
  methods: {
    filterFn(val, update, abort) {
        update(() => {
            const needle = val.toUpperCase();
            this.storesOptions = this.stores.filter(function(v) {
                let result = v.storeid.toUpperCase().indexOf(needle) > -1;
                let result1 = v.title.indexOf(needle) > -1;
                if (result || result1) {
                    return true;
                }
                return false;
            })
        })
    },
    handleSelectStore: function() {
        if (!this.curStore || this.curStore.length == 0) {
            this.$dialog.alert({
                message: '请先选择门店',
            });
            return;
        }

        console.log(this.curStore)
        this.storeName = this.curStore.title;
        this.storeId = this.curStore.storeid;
        this.dialogFormVisible = false;
        this.getData()

    },
    requestJsSdkConfig: function() {
        let that = this;
        // let agentId = common.config.agentId;
        // let corpId = common.config.corpId;
        let agentId = LocalStorage.getItem("agentId");
        let corpId = LocalStorage.getItem("corpId");
        this.axios.get(api.getJsSdkConfig, {
            params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split('#'))
            }
        })
            .then(function (response) {
            if (common.success(response)) {
                let data = response.data.data;
                that.wxConfigData = {nonceStr: data.nonceStr, timestamp: data.timestamp, signature: data.signature, appId: data.appId};
                that.wxConfig(that.wxConfigData)
            } else {
                // alert('获取JsSdkConfig失败：' + response.data.reason);
            }
            })
            .catch(function (error) {
            console.log(error);
            // alert('获取JsSdkConfig失败：' + error);
            })
            .then(function () {

            });
    },
    wxConfig: function(data) {
        let that = this
        wx.config({
            beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，企业微信的corpID
            timestamp: data.timestamp, //  必填，生成签名的时间戳
            nonceStr: data.nonceStr, //  必填，生成签名的随机串
            signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['previewImage','chooseImage','chooseImage','getLocalImgData'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        
        wx.ready(function () {
            console.log('ready');
            // let agentId = common.config.agentId;
            // let corpId = common.config.corpId;
            let agentId = LocalStorage.getItem("agentId");
            let corpId = LocalStorage.getItem("corpId");
            that.axios.get(api.getAgentJsSdkConfig, {
                params: {
                agentId,
                corpId,
                url: encodeURIComponent(window.location.href.split('#'))
                }
            })
            .then(function (response) {
                console.log(response,"AgentJsSdkConfig");
                if (common.success(response)) {
                    
                    let data = response.data.data;
                    
              
                } else {
                    // alert('获取AgentJsSdkConfig失败：' + response.data.reason);
                }
            })
            .catch(function (error) {
            console.log(error);
            // alert('获取JsSdkConfig失败：' + error);
            })
            .then(function () {

            });
            
            
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });

        wx.error(function (res) {
            console.log(res);
            // alert(JSON.stringify(res));
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    },
    getStore() {
      this.storeListLoading = true;
      this.axios
        .get(
          "/" + this.$ajaxPrefix.consumer + "/wxcp/brand/action/storeListByActionId?id=" +
            this.$route.query.id 
        )
        .then((res) => {
          this.storeListLoading = false;
          if (res.data.code == 0) {
            this.stores = res.data.data;
            if(this.stores.length == 1){
              this.curStore = this.stores[0]
              this.handleSelectStore()
            }
          } else {
          }

        });
    },
    getData() {
      this.axios
        .get(
          "/" + this.$ajaxPrefix.consumer + "/wxcp/brand/action/get?id=" +
            this.$route.query.id + '&storeId=' + this.storeId
        )
        .then((res) => {
          this.$toast.clear();
           console.log(res)
          if (res.data.code == 0) {
            let v = res.data.data.actionInfo
            const now = new Date().getTime();
            if(v.status == 1){
                v.state = '进行中'
              
              if(this.$moment(v.startTime).valueOf() > now){
                v.state = '未开始'
              }
              if(this.$moment(v.endTime).valueOf() < now){
                v.state = '已结束'
              }
                v.startTime = this.$moment(v.startTime).format('yyyy-MM-DD')
                v.endTime = this.$moment( v.endTime).format('yyyy-MM-DD')
            }else{
              v.state = '已结束'
            }
            this.actionInfo = v;
            res.data.data.recordList.map(v=>{
              v.description = api.imageHost + '/' + v.description
            })
            this.recordList = res.data.data.recordList
          } else {
            this.$toast({ message: "暂无该活动", duration: 2000 });
          }

        });
    },
    chooseImage: function (item) {
      console.log(item.name);
      var that = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original'], // '' 'compressed' 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: "normal", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        success: function (res) {
          console.log(res);
          var localIds = res.localIds; // 返回选定照片的本地ID列表，
          var localId = localIds[0];
          that.uploadImage(localId, item);
          // if (that.isIOS()) {
          //   that.showIOSImage(localId, item);
          // } else {
          //   item.image = localId;
          // }
          // andriod中localId可以作为img标签的src属性显示图片；
          // 而在IOS中需通过上面的接口getLocalImgData获取图片base64数据，从而用于img标签的显示
        }
      });
    },
    uploadImage: function (localId) {
      var that = this;
      wx.uploadImage({
        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          console.log('serverId:' + serverId);
          that.uploadImageToServer(serverId);
        }
      });
    },
    uploadImageToServer: function (serverId) {
      let that = this;
          this.$toast.loading({ 
      message: "正在上传中",
      forbidClick: true,
      duration: 0});
      this.axios.post("/" + this.$ajaxPrefix.consumer + '/wxcp/brand/action/addImage', {
          mediaId: serverId,
          code: that.$route.query.id,
          brand: that.actionInfo.brand,
          storeId:that.storeId
        }
      ).then(function (response) {
          that.$toast.clear();
          console.log('----- addImage -----');
          console.log(response);
          if (common.success(response)) {
            console.log(response);
            that.$toast({ message: "上传成功", duration: 2000 });
            that.getData()
          } else {
            that.$dialog.alert({
              message: response.data.reason,
            });
          }
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImg(item){
        let urls = [];
        this.recordList.map(v =>{
            urls.push(v.description)
        })
        wx.previewImage({
            current: item.description, // 当前显示图片的http链接
            urls: urls, // 需要预览的图片http链接列表
            success: function (res) {
                console.log(res,'previewImage');
            }
        });
    },
      deleteRecord(item){
        Dialog.confirm({
          message: "<p>确认删除此图片？</p>",
        })
          .then(() => {
            this.axios
            .get(
              "/" + this.$ajaxPrefix.consumer + "/wxcp/brand/action/delRecode?id=" +
                item.id
            )
            .then((res) => {
                this.$toast.clear();
                console.log(res);
                if (res.data.code == 0) {
                  this.$toast({ message: '删除成功', duration: 2000 });
                  this.getData()
                } else {
                  this.$toast({ message: res.data.msg, duration: 2000 });
                }
            });
          })
          .catch(() => {
            // on cancel
          });
      },

  },
  mounted() {
    document.title = "活动详情";
  },
};
